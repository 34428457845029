var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import Iconify from '../../../components/iconify';
import { useSettingsContext } from '../../../components/settings';
import usePermission from '../../../hooks/usePermission';
import { PATH_DASHBOARD } from '../../../routes/paths';
import SectionTable from '../../../sections/@dashboard/Configuration/Sections/SectionTable';
var SectionsList = function () {
    var themeStretch = useSettingsContext().themeStretch;
    var isSuperAdmin = usePermission().isSuperAdmin;
    var isAllowedToCreateSection = isSuperAdmin;
    return (_jsxs(_Fragment, { children: [_jsx(Helmet, { children: _jsx("title", { children: "Sections" }) }), _jsxs(Container, __assign({ maxWidth: themeStretch ? false : 'xl' }, { children: [_jsx(CustomBreadcrumbs, { heading: "Sections", links: [{ name: 'Sections' }], action: isAllowedToCreateSection && (_jsx(Button, __assign({ component: RouterLink, to: PATH_DASHBOARD.configuration.sections.new, variant: "contained", startIcon: _jsx(Iconify, { icon: "eva:plus-fill" }) }, { children: "New Section" }))) }), _jsx(SectionTable, {})] }))] }));
};
export default SectionsList;
